/* eslint-disable no-console */
import type { UserData, Tracker } from './tracker'

export class ConsoleTracker implements Tracker {
  track(eventName: string, eventProperties: Record<string, any>) {
    console.log(`Track: ${eventName}`, eventProperties)
  }

  identify(userData: UserData) {
    console.log('Identify:', userData)
  }

  async getFeatureFlag(name: string): Promise<undefined> {
    console.log('Getting feature flag:', name)
    return // no-op
  }

  async hasFeatureFlag(name: string): Promise<undefined> {
    console.log('Checking existing feature flag:', name)
    return false // no-op
  }

  clear() {
    console.log('Tracker cleared')
  }
}
