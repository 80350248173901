<template>
  <div class="h-screen pb-safe flex flex-col overflow-hidden">
    <top-bar show-back @back="() => $router.go(-1)">
      <template #center>
        <div>
          {{ $t('menu-availability.title') }}
        </div>
      </template>
    </top-bar>
    <div
      class="w-full md:w-3/5 px-4 md:px-0 mx-auto mt-4 flex flex-col flex-1 overflow-hidden"
    >
      <div class="flex flex-row w-full gap-4 flex-none">
        <l-search-bar
          v-model="query"
          class="w-2/3"
          val
          icon="search"
          size="medium"
          :debounce-time="400"
          :placeholder="$t('menu-availability.search')"
        />
        <div class="w-1/3">
          <l-select
            v-model="activeStateFilter"
            :options="productFilterOptions"
            size="medium"
          />
        </div>
      </div>
      <l-tabs
        v-model="activeTypeFilter"
        class="my-4 flex-wrap gap-y-4"
        :tabs="tabs"
      ></l-tabs>
      <template v-if="!isLoading">
        <template v-if="hasResults">
          <div class="flex flex-col flex-1 overflow-y-auto relative">
            <transition-group name="results-list" mode="out-in">
              <template v-for="tab in tabs.slice(1)" :key="tab.id">
                <div
                  v-if="
                    (activeTypeFilter === 'all' ||
                      activeTypeFilter === tab.id) &&
                    filteredStockData[tab.id as StockItemTypes].length > 0
                  "
                  class="mb-4"
                >
                  <div class="text-base text-n-10 font-medium">
                    {{ tab.name }}
                  </div>
                  <transition-group
                    name="results-list"
                    class="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-4"
                    tag="div"
                    @before-leave="beforeLeave"
                  >
                    <stock-list-item
                      v-for="item of filteredStockData[
                        tab.id as StockItemTypes
                      ]"
                      :key="item.id"
                      :item="item"
                    />
                  </transition-group>
                </div>
                <div
                  v-else-if="
                    activeTypeFilter === tab.id &&
                    filteredStockData[tab.id as StockItemTypes].length === 0
                  "
                  class="mt-4 flex flex-1 items-center"
                >
                  <empty-case
                    class="flex-1"
                    type="empty"
                    enforced-translation-key="search.no-results"
                  />
                </div>
              </template>
            </transition-group>
          </div>
        </template>
        <div v-else class="mt-4 flex flex-1 items-center">
          <empty-case
            class="flex-1"
            type="empty"
            enforced-translation-key="search.no-results"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import TopBar from '@/components/core/TopBar.vue'
import { storeToRefs } from 'pinia'
import { LSearchBar, LTabs, LSelect, EmptyCase } from '@last/core-ui/paprika'
import { useI18n } from 'vue-i18n'
import { useStockStore } from '@/store/stock'
import StockListItem from '@/components/MenuAvailability/StockListItem.vue'
import { StockItemTypes } from '@/types'

const { t } = useI18n()

const stockStore = useStockStore()
const {
  isLoading,
  hasResults,
  activeTypeFilter,
  activeStateFilter,
  query,
  filteredStockData
} = storeToRefs(stockStore)

stockStore.fetchStock()

const productFilterOptions = [
  { label: t('menu-availability.filter-by-all'), value: 'ALL' },
  { label: t('menu-availability.filter-by-enabled'), value: 'ENABLED' },
  { label: t('menu-availability.filter-by-disabled'), value: 'DISABLED' }
]

const tabs: { name: string; id: StockItemTypes | 'all' }[] = [
  {
    name: t('menu-availability.filter-by-all'),
    id: 'all'
  },
  {
    name: t('menu-availability.products'),
    id: 'product'
  },
  {
    name: t('menu-availability.combos'),
    id: 'combo'
  },
  {
    name: t('menu-availability.modifiers'),
    id: 'modifier'
  },
  {
    name: t('menu-availability.modifier-groups'),
    id: 'modifierGroup'
  }
]

function beforeLeave(element: Element) {
  const el = element as HTMLElement
  const { marginLeft, marginTop, width, height } = window.getComputedStyle(el)

  el.style.left = `${el.offsetLeft - parseFloat(marginLeft)}px`
  el.style.top = `${el.offsetTop - parseFloat(marginTop)}px`
  el.style.width = width
  el.style.height = height
}
</script>

<style scoped>
.results-list-enter-from {
  opacity: 0;
  transform: scale(0.6);
}
.results-list-enter-active {
  transition: all 0.4s ease;
}
.results-list-leave-to {
  opacity: 0;
  transform: scale(0.6);
}
.results-list-leave-active {
  transition: all 0.3s ease;
  position: absolute;
}
.results-list-move {
  transition: all 0.3s ease;
}
</style>
