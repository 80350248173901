<template>
  <div class="bg-n-800 flex flex-col w-full h-full pb-safe">
    <floor-plan-with-navigation
      :mode="floorplanMode"
      @table-selected="tableSelected"
      @merge-tabs="doMergeTabs"
      @assign-table="doAssignTable"
    />

    <floorplan-mode-selector v-model="floorplanMode" class="z-10" />
    <new-tab
      v-if="selectedTable && newTabRef"
      :table="selectedTable"
      :customer="customer"
      @close="closeModals"
      @tab-created="linkToReservationIfNeeded"
    />
    <tab-selector
      v-if="tabIdsToSelect"
      :tab-ids-to-select="tabIdsToSelect"
      @tab-selected="tabSelected"
      @close="tabIdsToSelect = null"
    />
    <merge-tabs
      v-if="mergingTabId"
      :tabs="tabsToMerge"
      :tab-id="mergingTabId"
      @close="mergingTabId = ''"
    />
    <assign-table
      v-model:is-active="showAssignTablePopup"
      :tab-ids="assignTableTabIds"
    />
  </div>
</template>

<script setup lang="ts">
import TabSelector from './TabSelector.vue'
import MergeTabs from '@/components/tabs/MergeTabs.vue'
import { useReservationsStore } from '@/store/reservations'
import { storeToRefs } from 'pinia'
import FloorplanModeSelector from './FloorplanModeSelector.vue'
import { ref } from 'vue'
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import NewTab from '@/components/tabs/NewTab.vue'
import { useTabsStore } from '@/store/tabs'
import { Table } from '@/types'
import AssignTable from '@/components/tabs/AssignTable.vue'
import { useDialog } from '@last/core-ui/paprika'
import { useI18n } from 'vue-i18n'
import moment from 'moment'
import FloorPlanWithNavigation from '@/components/tables/FloorPlanWithNavigation.vue'

const router = useRouter()
const reservationsStore = useReservationsStore()
const tabsStore = useTabsStore()
const dialog = useDialog()

const { editReservation } = reservationsStore
const { getReservation } = storeToRefs(reservationsStore)
const { tabs } = storeToRefs(tabsStore)
const { t } = useI18n()

const mergingTabId = ref<string>('')
const assignTableTabIds = ref<string[]>([])
const showAssignTablePopup = ref<boolean>(false)

const selectedTable = ref<{
  id: string
  seats: number
  tabIds: string[]
} | null>(null)
const newTabRef = ref<{
  lang: string | null
  name: string | null
  seats: number | null
} | null>(null)
const tabIdsToSelect = ref<string[] | null>(null)

const linkTabToReservationNeeded = ref(false)
const floorplanMode = ref<'time' | 'diners' | 'amount'>('time')

const tabsToMerge = computed(() => {
  return Object.values(tabs.value).filter(
    tab =>
      tab.open &&
      tab.id !== mergingTabId.value &&
      tab.source === 'Restaurant' &&
      tab.activationTime
  )
})

function doMergeTabs(tabId: string) {
  mergingTabId.value = tabId
}

function doAssignTable(tabIds: string[]) {
  assignTableTabIds.value = tabIds
  showAssignTablePopup.value = true
}

const customer = computed(() => {
  if (linkTabToReservationNeeded.value) {
    const reservation = getReservation.value(selectedTable.value!.id)
    if (!reservation) return null
    return {
      name: reservation.name,
      surname: reservation.surname,
      phoneNumber: reservation.phoneNumber,
      email: reservation.email
    }
  }
  return null
})

function tableSelected(table: Table) {
  if (table.tabIds.length === 1) {
    router.push({
      name: 'orderManagement',
      params: { tabId: table.tabIds[0] }
    })
  } else if (table.tabIds.length > 1) {
    tabIdsToSelect.value = table.tabIds
  } else {
    selectedTable.value = table
    const reservation = getReservation.value(table.id)
    if (reservation) {
      dialog({
        title: t('reservations.modal-title'),
        content:
          t('reservations.modal-text-info', {
            date: moment(reservation.dateTime).format('HH:mm')
          }) + t('reservations.modal-text-question'),
        mainLabel: t('reservations.cta-open-reservation'),
        secondaryLabel: t('reservations.cta-use-meanwhile'),
        onConfirm: () => linkReservation(),
        onSecondary: () => useTableWithReservation()
      })
    } else {
      createNewTab()
    }
  }
}

function createNewTab() {
  newTabRef.value = {
    seats: selectedTable.value!.seats,
    lang: null,
    name: null
  }
}

function closeModals() {
  selectedTable.value = null
  newTabRef.value = null
  clearReservationConfig()
}

function linkReservation() {
  linkTabToReservationNeeded.value = true
  useTableWithReservation()
}

function useTableWithReservation() {
  createNewTab()
}

function clearReservationConfig() {
  linkTabToReservationNeeded.value = false
}

function linkToReservationIfNeeded(tabId: string) {
  if (!linkTabToReservationNeeded.value) return
  const reservation = getReservation.value(selectedTable.value!.id)
  if (!reservation) return
  reservation.tabId = tabId
  editReservation(reservation)
}

function tabSelected(tabId: string) {
  tabIdsToSelect.value = null
  router.push({
    name: 'orderManagement',
    params: { tabId }
  })
}
</script>
