import SocketFactory from '@/socket.js'
import PrinterQueue from '@/integrations/printer/printerQueue'
import EscPos from '@/printers/escpos.js'
import api from '@/api'
import { useConfigStore } from '@/store/config'

async function discoverPrinters(existingPrinters) {
  let config = useConfigStore()
  if (config.device.mode !== 'master') return
  const subnets = await getSubnets()
  const printerIps = await getPrintersOnSubnets(
    subnets,
    existingPrinters.filter(printer => printer.type !== 'usb')
  )
  const lanPrinters = Object.values(await getPrintersFromIps(printerIps))
  const usbPrinters = await getUSBPrinters(
    existingPrinters.filter(printer => printer.type === 'usb')
  )
  const printers = [...lanPrinters, ...usbPrinters]
  await addPrinters(printers)
}

async function getPrintersOnSubnets(subnets, existingPrinters) {
  try {
    const hosts = subnets.flatMap(subnet =>
      Array(256)
        .fill()
        .map((element, index) => `${subnet}${index}`)
        .filter(
          ipAddress =>
            !existingPrinters.some(printer => printer.ip == ipAddress)
        )
    )
    const res = (
      await Promise.all(
        hosts.map(async host => {
          return await new Promise((resolve, _reject) => {
            let socket = SocketFactory.getSocket()
            socket.open(
              host,
              9100,
              () => {
                clearTimeout(to)
                socket.close()
                resolve(host)
              },
              () => {
                clearTimeout(to)
                socket.close()
                resolve(null)
              }
            )
            let to = setTimeout(function () {
              socket.close()
              resolve(null)
            }, 2000)
          })
        })
      )
    ).filter(p => p)

    return res
  } catch (e) {
    return []
  }
}
async function getSubnets() {
  return Array(11)
    .fill()
    .map((_v, index) => `192.168.${index}.`)
}
async function getPrintersFromIps(printerIps) {
  try {
    let printerId = 1
    let res = {}
    for (const printerIp of printerIps) {
      for (const type of ['EscPos']) {
        try {
          if (type === 'EscPos') {
            let p = new EscPos({
              type,
              address: printerIp
            })
            const status = (await p.getStatus())?.toString().charCodeAt(0)

            if (status) {
              res[printerIp] = {
                name: `Printer ${printerId}`,
                type,
                ip: printerIp
              }
              printerId++
            }
          }

          if (printerIp in res) {
            await printPrinterName({ ...res[printerIp] })
          }
        } catch (e) {
          return null
        }
      }
    }
    return res
  } catch (e) {
    return []
  }
}
async function getUSBPrinters(existingPrinters) {
  try {
    const { ipcRenderer } = window.require('electron')
    const printers = await ipcRenderer.invoke('get-connected-usb-printers')
    const filteredPrinters = printers
      .filter(printer => {
        if (existingPrinters.some(ePrinter => ePrinter.name === printer.name))
          return false
        else if (printer.portName) {
          let offline =
            printer.attributes && printer.attributes.includes('OFFLINE')
          return (
            (printer.name === 'TICKETS' ||
              printer.portName.startsWith('USB')) &&
            !offline
          )
        } else if (printer.options) {
          return printer.options['device-uri'].startsWith('usb://')
        } else {
          return false
        }
      })
      .map(printer => ({
        name: printer.name,
        type: 'Usb'
      }))

    let res = []

    for (const printer of filteredPrinters) {
      try {
        await printPrinterName(printer)
        res = [...res, printer]
      } catch (e) {
        res = [...res]
      }
    }

    return res
  } catch (e) {
    return []
  }
}

async function printPrinterName(printer) {
  let printerQ = new PrinterQueue(printer)
  await printText(printerQ, printer)
}

async function printText(printerQueue, printer) {
  let canvas = document.createElement('canvas')
  var ctx = canvas.getContext('2d')
  ctx.font = '28px Arial'
  ctx.fillText(`Name: ${printer.name}`, 10, 30)
  ctx.fillText(`Type:  ${printer.type}`, 10, 70)
  if (printer.ip) ctx.fillText(`IP: ${printer.ip}`, 10, 110)
  let imageData = canvas.toDataURL('image/png')
  const job = {
    type: 'printImage',
    image: imageData,
    time: new Date()
  }
  printerQueue.addJob(job)
}

async function addPrinters(printers) {
  let config = useConfigStore()
  await api.post('/printers', {
    printers,
    isMaster: config.device.mode === 'master'
  })
}

export default { discoverPrinters }
