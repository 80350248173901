<template>
  <div
    v-if="tab"
    class="h-screen pb-safe flex flex-col pb-2"
    :class="{ 'overflow-hidden': showBillDiscounts || showBillsList }"
  >
    <top-bar show-back :show-close="false" @back="router.go(-1)">
      <template #center>
        <div class="flex items-center text-xl font-heading font-bold">
          <div v-if="tab.code" class="flex items-center gap-1">
            {{ tab.code }}
            <span v-if="tab.tableName || tab.name">/</span>
            <span v-if="tab.tableName">{{ tab.tableName }}</span>
            <span v-else-if="tab.name">{{ tab.name }}</span>
          </div>
          <div v-else-if="tab.tableName">{{ tab.tableName }}</div>
          <div v-else>{{ tab.name }}</div>
          <div v-if="tab.activationTime" class="ml-4 text-n-400">
            {{ $filters.time(tab.activationTime) }}
          </div>
        </div>
      </template>
    </top-bar>

    <div class="flex flex-col h-full p-4">
      <charge-calculator
        class="mb-4"
        :payment-methods="paymentMethods"
        :initial-pending="(selectedBill && selectedBill.pending) || 0"
        :show-discounts="true"
        keypad-mode="full"
        display-mode="pending"
        @change-payment-method="selectPaymentMethod"
        @calculator-change="calculatorChange"
        @show-discounts="showBillDiscounts = true"
      />

      <l-button
        size="medium"
        class="w-full flex-none mb-2"
        type="secondary"
        @click="onShowBillsList"
      >
        {{ $t('checkout.view-payments') }}
      </l-button>
      <l-button
        size="medium"
        class="w-full flex-none"
        :disabled="isCharging"
        @click="charge"
      >
        {{ $t('checkout.charge') }} {{ currency(calculatorValues.toPay) }}
      </l-button>
    </div>

    <bill-discount
      v-if="showBillDiscounts"
      v-model:discount="discount"
      v-model:is-open="showBillDiscounts"
      :tab-id="tabId"
      :original-total="currentBill && currentBill.originalTotal"
      @close="showBillDiscounts = false"
      @cancel="showBillDiscounts = false"
    />
    <bills-list-modal
      v-if="showBillsList"
      :tab-id="tabId"
      :current-bill-id="currentBill && currentBill.id"
      :bills="bills"
      :selected-bill-id="selectedBillId"
      @close="showBillsList = false"
      @bill-selected="id => (selectedBillId = id)"
      @invalidate="invalidateAndRegenerate"
      @cancel-invoice="cancelInvoice"
      @generate-invoice="id => (generateInvoiceBillId = id)"
    />
  </div>
</template>

<script setup lang="ts">
import TopBar from '@/mobile/components/TopBar.vue'
import ChargeCalculator from '@/components/ChargeCalculator/ChargeCalculator.vue'
import { useTabs } from '@/composables/useTabs'
import { ref, computed, onMounted, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { CalculatorPayload } from '@/components/Calculator/CalculatorUtils'
import { useCheckout } from '@/composables/useCheckout'
import { useMoney } from '@/composables/useMoney'
import { LButton, useDialog } from '@last/core-ui/paprika'
import BillDiscount from '@/mobile/components/BillDiscount.vue'
import type { Discount } from '@/types'
import { usePromotionsStore } from '@/store/promotions'
import { storeToRefs } from 'pinia'
import BillsListModal from '@/mobile/views/BillsListModal.vue'
import { useTabsStore } from '@/store/tabs'
import { useI18n } from 'vue-i18n'

const route = useRoute()
const router = useRouter()

const tabId = ref<string>(route.params.tabId as string)
const showBillsList = ref<boolean>(false)

const tabsStore = useTabsStore()
const promotionsStore = usePromotionsStore()
const { tab, closeTab, allProducts } = useTabs(tabId)
const { currency } = useMoney()
const { t } = useI18n()
const currentDiscount = ref<Discount>()

const calculatorValues = ref<CalculatorPayload>({
  toPay: 0,
  pending: 0,
  change: 0,
  tip: 0
})

const showBillDiscounts = ref<boolean>(false)

const {
  selectedBillId,
  selectedBill,
  currentBill,
  bills,
  paymentMethods,
  isCharging,
  charge,
  selectPaymentMethod,
  checkoutCompleteWithPayments
} = useCheckout(tabId.value, 'normalCheckout', calculatorValues)

const { removeBill, regenerateBill } = tabsStore

const { getTabGlobalPromotion } = storeToRefs(promotionsStore)
const generateInvoiceBillId = ref<string>('')
const dialog = useDialog()

const tabGlobalPromotion = computed(() => {
  return getTabGlobalPromotion.value(tabId.value)
})

onMounted(() => {
  if (tabGlobalPromotion.value) {
    refreshGlobalPromotion()
  }
  if (bills.value && bills.value.length > 0) {
    selectedBillId.value = bills.value[0].id
  }
})

function refreshGlobalPromotion(): void {
  if (tabGlobalPromotion.value) {
    currentDiscount.value = {
      type: tabGlobalPromotion.value.discountType,
      amount: tabGlobalPromotion.value.discountAmount,
      promotionId: tabGlobalPromotion.value.promotionId || '',
      concept: tabGlobalPromotion.value.description
    }
  } else {
    currentDiscount.value = {
      type: 'percentage',
      amount: 0,
      concept: ''
    }
  }
}

function calculatorChange(value: CalculatorPayload) {
  calculatorValues.value = value
}

watch(checkoutCompleteWithPayments, complete => {
  let tabProducts = allProducts.value
  if (complete && tabProducts.length > 0) {
    closeTab()
  }
})

function deleteCurrentTabPromotion() {
  if (tabGlobalPromotion.value) {
    promotionsStore.deleteTabPromotion({
      tabId: tabId,
      tabPromotion: tabGlobalPromotion.value
    })
  }
}

const discount = computed({
  get() {
    return currentDiscount.value
  },
  set(discount) {
    if (!discount || !discount.type) {
      deleteCurrentTabPromotion()
      currentDiscount.value = undefined
    } else {
      promotionsStore
        .updateTabPromotion({
          tabId: tabId.value,
          promotion: {
            discountType: discount.type,
            discountAmount: discount.amount,
            name: discount.name,
            id: discount.promotionId,
            global: discount.global,
            description: discount.concept
          }
        })
        .then(() => {
          currentDiscount.value = discount
          refreshGlobalPromotion()
        })
    }
  }
})

function onShowBillsList(): void {
  showBillsList.value = true
}

function invalidateAndRegenerate(billId: string) {
  dialog({
    title: t('checkout.invalidate-bill'),
    content: t('checkout.invalidate-bill-content'),
    mainLabel: t('ctas.yes'),
    onConfirm: () => {
      removeBill({ tabId: tabId.value, billId })
      if (currentBill.value) {
        selectedBillId.value = currentBill.value.id
      } else if (bills.value && bills.value.length > 0) {
        selectedBillId.value = bills.value[0].id
      }
    }
  })
}

async function cancelInvoice(billId: string) {
  selectedBillId.value = await regenerateBill({
    tabId: tabId.value,
    billId: billId,
    customerCompany: undefined
  })
}
</script>
